import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import {Hidden, TextField} from "@mui/material";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{color: '#b3b3b3',}}>
            {'Copyright © '}
            <Link color="inherit" href="https://savvymembershipsclub.com/">
                Savvy Memberships
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Item({ children }) {
    return (
        <div style={{ padding: '20px' }}>
            {children}
        </div>
    );
}

const defaultTheme = createTheme();
const banner = process.env.PUBLIC_URL + '/assets/images/savvy-lifestyle-background-activation.jpg';
const bannerFooter = process.env.PUBLIC_URL + '/assets/images/savvy-out-to-lunch-couple.jpg';
const logo = process.env.PUBLIC_URL + '/assets/images/logo.png';

function App() {
    const [email, setEmail] = useState('');
    const [response, setResponse] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async () => {
        try {
            const response = await fetch('https://api.savvymemberships.com/subscriber/activate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();

            if (response.status === 201 && data.status === true) {
                setStatus('success');
                setResponse(data.message);
            } else {
                setStatus('error');
                setResponse(data.message);
            }
            setEmail('');
        } catch (error) {
            setStatus('error');
            setResponse('An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        // Remove error message after 30 seconds
        const timeout = setTimeout(() => {
            setResponse('');
            setStatus('');
        }, 10000);

        // Clear timeout on component unmount
        return () => clearTimeout(timeout);
    }, [response]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="relative" className="AppBar">
                <Toolbar className="Toolbar">
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={3}>
                            <img src={logo} alt="logo" className="Logo" />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Hidden mdDown>
                                <Typography variant="h6" color="inherit" noWrap className="Typography" textAlign="right">
                                    ACTIVATE YOUR MEMBERSHIP
                                </Typography>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <main>
                <Box
                    component="section"
                    sx={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover',
                        color: 'white',
                        padding: '100px 0',
                    }}
                >
                    <Container maxWidth="md" align="center" sx={{ margin: '0 50px', padding: '0', width: 'auto' }}>
                        {/* Text Over Image */}
                        <Typography variant="h4" component="h2" align="center" gutterBottom>
                            Activate your Account!
                        </Typography>

                        {/* Opacity Background */}
                        <Box
                            sx={{
                                backgroundColor: 'rgb(20 71 108 / 70%)',
                                padding: '20px',
                                borderRadius: '0',
                                marginTop: '20px',
                                width: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* Text Below Image */}
                            <Typography variant="h4" align="center" paragraph>
                                Enter your Email Address below<br />
                                to Activate your Account
                            </Typography>

                            {/* Input Field */}
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    sx: {
                                        background: 'white',
                                        borderRadius: '0',
                                        width: '100%'
                                    },
                                }}
                                sx={{
                                    width: '60%'
                                }}
                            />
                            {response && (
                                <Typography sx={{ color: status === 'success' ? '#1fc81f' : 'red' }}>
                                    {response}
                                </Typography>
                            )}
                        </Box>
                        <br/>
                        <Button variant="contained" className="buttonCustomStyles" onClick={handleSubmit}>
                            <b>Activate</b>
                        </Button>
                    </Container>
                </Box>
                <Box
                    component="section"
                    align="center"
                    sx={{
                        backgroundColor: '#b3b3b3',
                        backgroundSize: 'cover',
                        color: '#051c2d',
                        padding: '30px 0',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Item>
                                <Typography variant="h3" gutterBottom>
                                    WHY SAVVY?
                                </Typography>
                                <Typography variant="h5">
                                    SAVVY is not just a membership; it's your gateway to an elevated lifestyle,<br/>
                                    offering unmatched savings across a constellation of premium services and<br/>
                                    experiences. Discover how Edge gives you <u>more for less, every day.</u>
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={2}>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Item>
                                <Typography variant="h5">
                                    <u>Our motto is Dine, Indulge, Live, and Explore!</u>
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    component="section"
                    align="center"
                    sx={{
                        backgroundColor: '#051c2d',
                        backgroundSize: 'cover',
                        color: '#b3b3b3',
                        padding: '30px 0',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Typography variant="h3">
                                        <u>DINE</u>
                                    </Typography>
                                    <Typography variant="h5">
                                        Enjoy on AVERAGE of 44% off at<br/>
                                        over 60,000 esteemed dining<br/>
                                        spots. Curated selections rated<br/>
                                        highly on Yelp® and Trip Advisor®<br/>
                                        ensure every meal is a delight.</Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Typography variant="h3">
                                        <u>LIVE</u>
                                    </Typography>
                                    <Typography variant="h5">
                                        From movie nights to sporting<br/>
                                        events, get up to 50% off at<br/>
                                        50,000+ venues for entertainment<br/>
                                        that doesn't break the bank
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Typography variant="h3">
                                        <u>INDULGE</u>
                                    </Typography>
                                    <Typography variant="h5">
                                        Access premium shopping with<br/>
                                        substantial savings. 200,000+<br/>
                                        locations offer deals on the<br/>
                                        finest brands, turning your retail<br/>
                                        escapades into savvy savings.
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Typography variant="h3">
                                        <u>EXPLORE</u>
                                    </Typography>
                                    <Typography variant="h5">
                                        From movie nights to sporting<br/>
                                        events, get up to 50% off at<br/>
                                        50,000+ venues for<br/>
                                        entertainment that doesn't break<br/>
                                        the bank.
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    component="section"
                    sx={{
                        backgroundImage: `url(${bannerFooter})`,
                        backgroundSize: 'cover',
                        color: 'white',
                        padding: '100px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Container maxWidth="md" align="center" sx={{
                        margin: '0 50px',
                        padding: '0',
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                    }}>
                        <Box
                            sx={{
                                backgroundColor: 'rgb(43 114 167 / 70%)',
                                padding: '20px',
                                borderRadius: '0',
                                marginTop: '20px',
                                width: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* Text Below Image */}
                            <Typography variant="h4" align="right" paragraph>
                                "Edge has truly upgraded<br/>
                                my date nights. We've<br/>
                                dined at the finest spots in<br/>
                                town for half the<br/>
                                price—feels like I get a<br/>
                                third date free everytime!"<br/>
                                - Greg M
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </main>
            {/* Footer */}
            <Box sx={{ backgroundColor: '#051c2d', p: 6, color: '#b3b3b3 !important', }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>
                    Savvy
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                    sx={{color: '#b3b3b3'}}
                >
                    All rights reserved!
                </Typography>
                <Copyright />
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
}

export default App;